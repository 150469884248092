import ExerciseWrapper from 'components/exercises/ExerciseWrapper';
import WritingExercise from 'components/exercises/WritingExercise';
import { ExerciseType } from 'constants/exercises';

const exercise = {
  id: '664c08c7a430c1f1e7716c72',
  title: 'Rockwave Festival',
  type: 'writing',
  text: [
    'Rockwave is the biggest music festival in Greece, and one of the largest in Europe. Rockwave attracts masses of young Greeks and foreign visitors.',
    "The Rockwave Festival takes place at Terra Vibe, an outdoor theme park and events venue with a capacity of 15,000. Terra Vibe is surrounded by outstanding natural beauty, and as an organization it respects the environment, so you can feel 'green' about attending.",
    'The festival includes two full days of music: one day of rock and one day of heavy metal. The performances start at 3:30 p.m. each day. Popular acts have included Franz Ferdinand, The Dandy Warhols, Twisted Sister and WASP.',
    'General admission for Rockwave is 41.50 euros per day or 74.50 euros for both days of the festival. Visitors to the Rockwave Festival can reach the site by train from Athens, and the organizers also schedule special buses for some of the concerts.',
  ],
  task: 'Write an article for a magazine describing your experience at the Rockwave Festival. Include details about why you went, what the atmosphere and performances were like, and make a recommendation regarding the festival.',
};

const WritingExercisePage = () => (
  // <ExerciseWrapper
  //   exercise={WritingExercise}
  //   exerciseType={ExerciseType.Writing}
  // />
  <WritingExercise exercise={exercise} />
);

export default WritingExercisePage;
