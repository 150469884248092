import { Button, Drawer } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import Message from 'components/Chat/Message';
import Loader from 'components/common/Loader';
import EmptyState from 'components/ui/empty-state';

const ChatHistory = ({
  open,
  setOpen,
  messages,
  isSuccess = true,
  isLoading = false,
}) => {
  const toggleOpen = () => setOpen(prevOpen => !prevOpen);

  return (
    <Drawer
      open={open}
      onClose={toggleOpen}
      closable={false}
      title='Chatbot History'
      styles={{
        header: { padding: '20px 24px' },
      }}
      width={500}
      extra={
        <Button
          type='text'
          shape='circle'
          icon={<CloseCircleOutlined style={{ fontSize: '20px' }} />}
          onClick={toggleOpen}
        />
      }
    >
      <div className='flex flex-col gap-5 overflow-y-auto'>
        {isLoading ? (
          <Loader />
        ) : !isSuccess ? (
          <p>Error loading chat</p>
        ) : messages.length > 0 ? (
          messages.map(({ user_message, ai_message, date_time }) => (
            <>
              {user_message && (
                <Message sender='user' date={date_time}>
                  {user_message}
                </Message>
              )}
              {ai_message && (
                <Message sender='bot' date={date_time}>
                  {ai_message}
                </Message>
              )}
            </>
          ))
        ) : (
          <EmptyState message='No messages were sent during this attempt' />
        )}
      </div>
    </Drawer>
  );
};

export default ChatHistory;
