import classNames from 'classnames';
import { Card } from 'components/ui/card';
import moment from 'moment';

const Message = ({ children, sender, date, options, onClickOption }) => {
  const studentIsSender = sender === 'user';

  return (
    <Card
      className={classNames('flex w-fit min-w-48 max-w-message flex-col p-4', {
        'self-end bg-sky-200': studentIsSender,
      })}
    >
      <p>{children}</p>
      {options && (
        <div className='my-3'>
          <p className='text-bold mb-2'>Here are some examples: </p>
          <div className='flex flex-col gap-2'>
            {options.map(option => (
              <span
                key={option}
                className='cursor-pointer underline hover:text-slate-700'
                onClick={() => onClickOption({ message: option })}
              >
                {option}
              </span>
            ))}
          </div>
        </div>
      )}
      {date && (
        <span className='-mb-1 -mr-1 self-end text-xs text-slate-500'>
          {moment(date).format('DD MMM yyyy HH:mm')}
        </span>
      )}
    </Card>
  );
};

export default Message;
