import RouterProvider from 'providers/RouterProvider';
import { ConfigProvider } from 'antd';
import AuthProvider from 'providers/AuthProvider';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createSyncStoragePersister } from '@tanstack/query-sync-storage-persister';
import { PersistQueryClientProvider } from '@tanstack/react-query-persist-client';
import PWAPrompt from 'react-ios-pwa-prompt';
import './App.css';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: 1000 * 60 * 60 * 24, // 24 hours
      staleTime: Infinity,
    },
  },
});

const persister = createSyncStoragePersister({
  storage: window.localStorage,
});

const App = () => (
  <PersistQueryClientProvider
    client={queryClient}
    persistOptions={{ persister }}
  >
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: '#0ea5e9',
        },
      }}
    >
      <AuthProvider>
        <RouterProvider />
        <PWAPrompt
          promptOnVisit={1}
          timesToShow={3}
          copyClosePrompt='Close'
          permanentlyHideOnDismiss={false}
        />
      </AuthProvider>
    </ConfigProvider>
    <ReactQueryDevtools initialIsOpen={false} />
  </PersistQueryClientProvider>
);

export default App;
